import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import FAQs from '../components/FAQs'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, Link } from "gatsby"
import '../styles/Hero'
import '../styles/TwoCols'

import CLOUDSYNC from '../images/hybrid/cloud-sync.svg'
import SYNC from '../images/hybrid/sync.svg'
import UPTIME from '../images/hybrid/uptime.svg'

import FAQS from '../json/faqs/hybrid.json'


export default function HybridPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/hybrid/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='Hybrid' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>Best of Both Worlds ...</div>
              <div className='hero-slanted'>Online and Local</div>
            </div>
          </div>
        </BackgroundImage>

        <div className='container'>
          <div className="heading-separator"/>
          <h1 className="title">Hybrid Cloud</h1>
          <h2 className="subtitle">The only way to guarantee 100% uptime</h2>
          <div className="description">
            <p>
              Hybrid Cloud is a modern approach to hosting your mission critical applications.
              You get the best of both worlds: being in the cloud with the ability to share with your customers and work on the go, as well as get lightning fast performance while in the office.
              It also insulates you from network issues and latencies.
            </p>
            <p>
              At its core, it's a real-time sync between your servers in the office and your app servers in the cloud. 
              Thanks to some solid innovation by our team, we are able to properly handle any conflicts between both server clusters so you have real-time consistency in all your data.
            </p>
          </div>

          <div className="twocols">
            <div className="twocols-col-image">
              <img src={CLOUDSYNC}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#1 - Servers</div>
              <div className="heading">Local & Cloud</div>
              <div className="description">
                <p>A key part of this innovation is having separate stateless application servers (local and in the cloud) which do not communicate with each others.</p>
                <p>However, your data and documents look the same whether you are online or local.</p>
                <p className="quote">A key advantage of hybrid clouds is that the redundancy acts as backups. Not to mention that OneOffice local servers are low-end low-cost servers thanks to our relentless optimizations.</p>
                <p>Some services which require secure connections (e.g. calls) will continue to be in the cloud regardless, but its data (chats, metadata etc.) will be sync-ed locally as well.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={SYNC}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#2 - Real-time Sync</div>
              <div className="heading">Reliable & Resiliant</div>
              <div className="description">
                <p>All data, whether documents or in the database are sync-ed real-time.</p>
                <p>This is more complex than it seems. What if you are editing a document locally, and someone else is editing in the cloud. How do you reconcile?</p>
                <p className='quote'>Careful locking mechanisms ensure files are not corrupted due to simultaneous access. Data integrity is maintained throughout.</p>
                <p>This is supported by real-time disaster recovery systems that keep version history in brute-foce (i.e. the entire document is backed up with its timestamp).</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={UPTIME}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#3 - 100% Uptime</div>
              <div className="heading">The Elusive Dream</div>
              <div className="description">
                <p>If you check the SLA on your hosting provider, you are typically looking at 99.9% or so.
                  It's practically impossible to guarantee zero down-time</p>
                <p>Search engines, social media, chat apps have all gone down at one point or the other.</p>
                <p className="quote">The advantage of a local cloud is that you are shielding yourself from the one component that no hosting provider can guarantee: Internet network availability.</p>
              </div>
            </div>
          </div>


          <div className="subheading mt-30" id="faqs">Hybrid Hosting FAQs</div>
          <div className="heading">Answers to Common Hybrid Questions</div>
          <div className="description">
            For more answers please visit the <Link to='/faqs'>complete FAQs</Link> or just <Link to='/contact'>drop us a line</Link>.
          </div>

          <FAQs faqs={FAQS} className='mb-20'/>

          <CTA variant={4}/>
        </div>

      </main>
    </Layout>
  )
}
